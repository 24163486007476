import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface IGetOrganizationsList {
  id_organizacion: string;
  available_admin_access: AvailableAdminAccess[];
  update_date: Date;
  fecha_formatted?: string;
  description: string;
}

export interface AvailableAdminAccess {
  accessID?: string;
  name: string;
  active: boolean;
  type: string;
  display: string;
  group: string;
}

enum EUOrganizacionServiceAcctions {
  CREATE_ORGANIZATION = "createOrganization",
  GET_AVAILABLE_ACCESS_SYSTEM = "getMasterAccessList",
  SEARCH_ORGANIZATION = "searchOrganization",
  UPDATE_ORGANIZATION = "configureOrganizationAccess",
  GET_AVAILABLE_ORGANIZATIONS = "getOrganizationsConfigurations"
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { }

  // {
  //   "userPoolID": this.userPoolID,
  //   "userName": user.username,
  //   "name": user.firstName,
  //   "givenName": user.givenName,
  //   "phoneNumber": user.phoneNumber,
  //   "organization": this._sessionService.getOrganization(),
  //   "accessList": user.systemUserAccessList,
  //   "profilesList": ["WEB"], // NOTE: this is the default user profilesList for "activated"
  //   "operation": EUsuarioServiceAcctions.CREATE_USER
  // }

  searchUser(idOrganizacion: string, user: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName": user,
      "organization":idOrganizacion,
      "operation" : "searchUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});    
  }

  createUser(idOrganizacion: string, email: string, name: string, givenName: string, role: string) {
    
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName": email,
      "name": name,
      "phoneNumber": "",
      "givenName": givenName,
      "organization": idOrganizacion,
      "accessList": [
          role
      ],
      "profilesList": [
          "WEB"
      ],
      "operation": "createUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  getUsers(idOrganizacion: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
        "organization" : idOrganizacion,
        "operation":"getUsersForGroup"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  deleteUser(idOrganizacion:string, email: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName" : email,
      "organization":idOrganizacion,
      "operation":"deleteUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  getUserAccess(idOrganizacion: string, email: string) {
    const body = {
      "userName": email,
      "organization": idOrganizacion,
      "operation" : "getAccessForUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  public getOrganizations(idOrganizacion: string, email: string): Observable<IGetOrganizationsList[]> {

    const payload = {
      "userPoolID": environment.cognito.userPoolID,
      "organization": idOrganizacion,
      "operation": EUOrganizacionServiceAcctions.GET_AVAILABLE_ORGANIZATIONS,
      "userName":  email
    };

    return this._http.post<IGetOrganizationsList[]>(environment.apis.userAccess + 'configurateOrganization', payload);
  }

  public createOrg(newIdOrganizacion: string, description: string, rutOrganization: string, displayName: string, idOrganizacion: string, email: string): Observable<any> {
    const payload: any = {
      "newOrganization": newIdOrganizacion,
      "userPoolID": environment.cognito.userPoolID,
      "organization": idOrganizacion,
      "inputServiceConfigurations": [
        {
            "name": "USER-ADMIN",
            "active": true
        },
        {
            "name": "ACCESO_BASICO",
            "active": true
        }
      ],
      "userName": email,
      "operation": EUOrganizacionServiceAcctions.CREATE_ORGANIZATION,
      "description": description,
      "rutOrganization": rutOrganization,
      "displayName": displayName
    }

    return this._http.post(environment.apis.userAccess + 'configurateOrganization', payload);
  }
}
